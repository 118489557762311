<div mat-dialog-title>
  <h1>{{ titleProp }}</h1>
  <td-dialog-close-button />
</div>
<div mat-dialog-content>
  @if (description) {
    <td-p-with-highlights [value]="description" level="p2" />
  }
  <ng-content/>
  <aside>
    By submitting personal data, you consent to emails from TypeDB. See our
    <a href="/legal/privacy-policy" target="_blank">Privacy Policy</a>.
  </aside>
</div>
@if (isSubmitting) {
  <mat-progress-bar mode="indeterminate" />
}
