import { loginSchemas } from "./login";
import { onboardingSchemas } from "./onboarding";
import { cloudProviderSchemas } from "./provider";
import { geographySchemas } from "./geography";

export const cloudPlatformSchemas: any[] = [
    ...cloudProviderSchemas,
    ...geographySchemas,
    ...loginSchemas,
    ...onboardingSchemas,
];
